
.brand-section {
	--heading-text: #040405;
	--primary-border-light: rgba(0, 134, 230, 0.20);
	--primary-background-light: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
	padding: 40px 0 110px 0;
    background: #F4FAFE;
    min-height: 590px;

    .brand-content {
        padding-bottom: 40px;
        h2 {
            font-size: 40px;
            line-height: 150%;
            font-weight: 700;
            color: #040405;
            text-align: center;
            margin: 0;
        }
    }

    .brand-compeny-card {
        width: 100%;
        margin-bottom: 30px;
        border-radius: 16px;
        border: 2px solid rgba(0, 134, 230, 0.20);
        background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);        
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 16px;
        &:hover {
            box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);
        }
        img {
            height: 36px;
            width: auto;
        }
    }
}
.brand-guttur-space {
    --bs-gutter-x: 30px;
}

@media (max-width: 1400px) and (min-width: 1025px) {
    .brand-section .brand-compeny-card img {
        height: 26px;
    }
}

@media (max-width: 1400px) and (min-width: 1025px) {
    .home-banner-section a.bannerlink {
        font-size: 15px;
    }
}
@media (max-width: 1024px) {
    .brand-section {
        padding: 10px 0 50px 0;
        min-height: auto;
        .brand-content {
            padding-bottom: 32px;
            h2 {
                font-size: 32px;
                line-height: 40px;
            }
        }

        .brand-compeny-card {
            padding: 24px 16px;
            img {
                width: 100%;
                height: 30px;
            }
        }
    }
    .brand-guttur-space {
        --bs-gutter-x: 23px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1112px) {
    .container {
        max-width: 1000px !important;
    }
    .brand-section {
        min-height: 550px !important;
    }
}

@media (max-width: 992px) {
    .brand-section {
        padding-bottom: 40px;
        .brand-content {
            padding-bottom: 40px;
            h2 {
                font-size: 24px;
                line-height: 34px;
            }
        }
        .brand-compeny-card {
            margin-bottom: 16px;
        }
    }
}

@media (max-width: 767px) {
    .brand-section {
        padding: 0 0 60px 0;
        .brand-compeny-card {
            padding: 17px 16px;
            margin-bottom: 10px;
            border-radius: 12px;

            img {
                max-width: 100%;
                width: 100%;
                height: 26px;
            }
        }
        .brand-content {
            padding-bottom: 24px;
        }
    }
    .brand-guttur-space {
        --bs-gutter-x: 0.5rem;
    }
}


@media (max-width: 480px) {
    .brand-section {
        padding: 0 0 60px 0;
        .brand-compeny-card {
            padding: 17px 10px;
            margin-bottom: 10px;
            border-radius: 12px;
        }
    }
}

.section-pad-0 {
    padding: 0 !important;
}
